import React from "react"
import News from "../../blocks/news"
import { Link } from "gatsby"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "maksimov_vs_fifa" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Форвард <Link to={ `/players/maksimov` }>Максим Максимов</Link>, игравший за воронежский «Факел», литовский «Тракай», македонский «Вардар» и латвийский РФШ, возобновит футбольную карьеру после того, как представляющее его агентство Catenaccio Sports Agency убедило Арбитражный Спортивный Суд в Лозанне признать решение ФИФА о дисквалификации форварда несостоятельным.</p>
          <p>Напомним, что решение касалось перехода Максимова из «Тракая» в «Вардар» летом 2017-го года. Форвард расторг соглашение с «Тракаем» в соответствии с контрактными условиями за сутки до закрытия трансферного окна и перешел в «Вардар» свободным агентом.</p>
          <p>Тем не менее, ФИФА запретила 23-летнему россиянину, который в сезоне 2017/18 длительное время возглавлял список бомбардиров в Лиге Европы (7 голов), выступать на профессиональном уровне вследствие судебного иска, поданного московским спортивным юристом Михаилом Прокопцом (компания SILA), представляющим интересы литовского клуба.</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/ZtDLQKa0of4"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
          <p>Несмотря на упорное сопротивление ФИФА и Прокопца, Catenaccio Sports Agency, представляющее интересы игрока, достигла снятие запрета, убедив Арбитражный Спортивный Суд принять сторону игрока и отвергнуть доводы проигравшей стороны. Теперь Максим Максимов, входящий в сферу интересов многих клубов РПЛ, возобновляет карьеру.</p>
          <p>– Мы были очень недовольны решением по делу Максима Максимова, которое первоначально вынесла ФИФА и поэтому без колебаний подали апелляцию в CAS. После того, как сегодня CAS отменило решение FIFA, я думаю, что нашим оппонентам тоже дали понять, что дело не такое однозначное, как они предполагали и о чем говорили в публичном пространстве. Могу даже предположить, что оно может стать важным прецедентом в мировом футболе, – прокомментировал ситуацию «Спорту День за Днем» руководитель Catenaccio Sports Agency Максим Усанов.</p>
        </div>
      </div>
    )
  }
}